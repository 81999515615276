import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { BsLocaleService } from 'ngx-bootstrap';

import { Globals } from 'app/shared/services/globals.service';
import { AuthService } from 'app/shared/services/auth.service';
import { SessionService } from 'app/shared/services/session.service';
import { Page } from 'app/shared/model/page';

import { EntryService } from 'app/crud/sales/entry/entry.service';
import { Entry } from 'app/crud/sales/entry/entry';

import { WhyService } from 'app/crud/sales/why/why.service';
import { Why } from 'app/crud/sales/why/why';

@Component({
  selector: 'app-entry-table',
  templateUrl: './entry-table.component.html',
})
export class EntryTableComponent implements OnInit {

  // Select fields
  whyoptions: Why[] = [];

  // Defalt form mode
  mode = 'edit';

  // Search fields
  search = {
    createdon: '',
    contact: '',
    subject: '',
    message: '',
    crmid: '',
    email: '',
    phone: '',
    zone: '',
    why_id: '',
    why_name: '',
    transferred: '',
  };

  // Current page
  page = 0;

  // Sort field and direction
  //? sort = 'createdon';
  //? dir = '+';
  sort = '';
  dir = '';

  // Current filter
  filter = '';

  // One page of data
  data: Page<Entry>;

  // Loading indicator
  isLoading = true;

  // Event debouncer
  debounce: any;

  constructor(
    private apiWhy: WhyService,
    private api: EntryService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private session: SessionService,
    private localeService: BsLocaleService,
    private globals: Globals) {
      this.localeService.use(this.session.lang.code);
      if (this.session.search.form == 'Entry') {
        this.search = this.session.search.data;
        this.sort = this.session.search.sort;
        this.dir = this.session.search.dir;
        this.setFilter();  
      }
    }

  ngOnInit() {
    // Mode, if sub-table
    if (this.route.snapshot.data.mode !== undefined) {
      this.mode = this.route.snapshot.data.mode;
    }

    // Load why lookup
    this.loadWhy();

    // Loads the table
    this.load();
  }

  // Loads the table
  load() {
    this.isLoading = true;
    this.api.read(this.page, this.dir + this.sort, this.filter).subscribe(
      res => {
        this.data = res;
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        this.globals.crudKo('read', 'ENTRY', err);
      }
    );
  }

  isSorted(field: string) {
    if (field === this.sort && this.dir === '-') {
      return 'sorting_desc';
    } else if (field === this.sort && this.dir === '+') {
      return 'sorting_asc';
    }
    return 'sorting';
  }

  doPage(page: number) {
    // Change page
    this.page = page;
    this.load();
  }

  doSort(field: string) {
    // Not sorted? Sort asc
    if (this.sort !== field) {
      if (field === 'createdon' || field === 'updatedon')
      	this.sort = 'audit.' + field;
      else
      	this.sort = field;
      this.dir = '+';
    } else {
      // Sorted asc? Sort desc
      if (this.dir === '+') {
        this.dir = '-';
      } else {
        // Else do not sort
        this.sort = '';
        this.dir = '';
      }
    }

    // Save sort
    this.session.search.form = 'Entry';
    this.session.search.data = this.search;
    this.session.search.sort = this.sort;
    this.session.search.dir = this.dir;

    // Reload
    this.load();
  }

  setFilter() {
    // Filter string
    const f: string[] = [];
    Globals.pushFilter(f, 'audit.createdon', this.search.createdon, 'eq', this.session.lang.code, 'Date');
    Globals.pushFilter(f, 'contact', this.search.contact, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'subject', this.search.subject, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'message', this.search.message, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'crmid', this.search.crmid, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'email', this.search.email, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'phone', this.search.phone, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'zone', this.search.zone, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'why.id', this.search.why_id, 'eq', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'why.name', this.search.why_name, 'like', this.session.lang.code, 'String');
    Globals.pushFilter(f, 'transferred', this.search.transferred, 'eq', this.session.lang.code, 'Boolean');
    this.filter = Globals.encode(f.join(','));

    // Save filter
    this.session.search.form = 'Entry';
    this.session.search.data = this.search;
    this.session.search.sort = this.sort;
    this.session.search.dir = this.dir;
  }

  doFilter(event: any) {
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.setFilter();
      this.page = 0;
      this.load();
    }, 400);
  }

  // Clear filter
  clearFilter() {
    // Clear filter string
    this.search.createdon = '';
    this.search.contact = '';
    this.search.subject = '';
    this.search.message = '';
    this.search.crmid = '';
    this.search.email = '';
    this.search.phone = '';
    this.search.zone = '';
    this.search.why_id = '';
    this.search.why_name = '';
    this.search.transferred = '';

    // Clear
    this.session.search.data = this.search;

    // Search
    this.page = 0;
    this.filter = '';
    this.load();
  }

  // Load why lookup
  loadWhy() {
    this.apiWhy.readAll('').subscribe(
      res => {
        this.whyoptions = res.content;
      },
      err => {
        // this.globals.crudKo('read lookup', 'WHY', err);
      }
    );
  }

  mimeIcon(type: string) {
    return Globals.mimeIcon(type);
  }

}
